@import '../../variables';
.prefooter {
  display: flex;
  justify-content: center;
  list-style: none;
  flex-direction: column;
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: $color_2;
  color: $color_4;
  @media (min-width: 768px){
    flex-direction: row;
  }
  li {
    padding: 10px 20px;
  }
}
.Footer {
  font-family: $layout;
  background: $color_4;
  color: $color_2;
  width: 100%;
  z-index: 10;
  @media (min-width: 768px){
    height: 70px;
  }
  .inner {
    max-width: 1600px;
    width: 100%;
    margin: 0 auto;
    padding: 0 30px;
    box-sizing: border-box;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // flex-direction: column;
    // flex-wrap: nowrap;
    align-items: center;
    overflow: hidden;
    @media (min-width: 768px){
      // justify-content: flex-start;
      // flex-direction: row;
      display: grid;
      grid-template-columns: max-content 1fr max-content;
    }
    & > a {
      text-align: center;
      @media (min-width: 768px){
        margin: 0 75px 0 0;
      }
      .logo {
        min-width: 300px;
        img {
          width: auto;
          height: 70px;
          padding: 15px;
          box-sizing: border-box;
        }
      }
    }
    ul {
      display: flex;
      margin: 0;
      padding: 0;
      list-style-type: none;
      flex-wrap: wrap;
      overflow: hidden;
      justify-content: center;
      // align-items: center;
      @media (min-width: 768px){
        height: 70px;
        justify-content: flex-start;
      }
      li {
        line-height: 70px;
        margin: 0px 25px;
        @media (min-width: 768px){
          &:first-of-type{
            margin-left: 0;
          }
          &:last-of-type{
            margin-right: 0;
          }
        }
      }
    }
  }
}
