.Image {
  span {
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
  }
  img {
    max-width: 100%;
  }
}
