@import '../../variables';
.Post {
  .header {
    max-width: $max_content_width;
    margin: 3em auto 1em;
    padding: 0 20px;
    h1 {
      font-size: 50px;
      margin: 0;
    }
  }
  .content {
    &::after {
      content: '';
      display: table;
      clear: both;
      padding-top: 14px;
    }
  }
}
