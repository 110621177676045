@import '../../variables';
@keyframes slideRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes slideRightOut {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}
.Header {
  font-family: $layout;
  background: $color_4;
  color: $color_2;
  height: 70px;
  width: 100%;
  position: sticky;
  top: 0;
  box-shadow: 0 6px 15px 2px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.5s ease-in-out;
  z-index: 10;
  &.top {
    box-shadow: none;
  }
  & > .inner {
    max-width: 1600px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    overflow: hidden;
    // @media (min-width: 1024px){
    //   justify-content: flex-start;
    // }
    & > nav {
      @media (min-width: 1024px){
        margin-right: 30px;
      }
    }
    & > a {
      flex: 1;
      @media (min-width: 1024px){
        flex: unset;
        margin: 0 75px 0 0;
      }
      .logo {
        min-width: 300px;
        img {
          width: auto;
          height: 70px;
          padding: 15px;
          box-sizing: border-box;
        }
      }
    }
    .hamburger {
      font-size: 20px;
      margin: 0 30px;
      & > svg {
        cursor: pointer;
      }
      @media (min-width: 1024px){
        display: none;
      }
      .HeaderMobile {
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 11;
        transform: translateX(100%);
        animation: slideRight 0.5s ease;
        animation-fill-mode: both;
        &.slideOut {
          animation: slideRightOut 0.5s ease;
        }
        .inner {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          max-width: 500px;
          margin: 0 0 0 auto;
          background: $color_4;
          .close {
            font-size: 30px;
            margin-top: 30px;
            margin-right: 30px;
            text-align: right;
            cursor: pointer;
          }
          .logo {
            min-width: 300px;
            img {
              width: 100%;
              height: auto;
              padding: 35px;
              box-sizing: border-box;
            }
          }
        }
        nav {
          flex: 1;
          ul {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            max-height: none;
            li {
              margin: 0;
            }
          }
          &.social {
            flex: unset;
            margin-bottom: 30px;
            ul {
              flex-direction: row;
              li {
                margin: 0px 25px;
                &:first-of-type{
                  margin-left: 0;
                }
                &:last-of-type{
                  margin-right: 0;
                }
              }
            }
          }
        }
      }
    }
    ul {
      display: none;
      margin: 0;
      padding: 0;
      list-style-type: none;
      flex-wrap: wrap;
      overflow: hidden;
      max-height: 70px;
      @media (min-width: 1024px){
        display: flex;
      }
      li {
        line-height: 70px;
        margin: 0px 25px;
        &:first-of-type{
          margin-left: 0;
        }
        &:last-of-type{
          margin-right: 0;
        }
      }
    }
  }
}
