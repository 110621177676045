@import '../../variables.scss';
@mixin block {
  max-width: $max_content_width;
  // margin: 32px auto;
  margin: 0 auto;
  box-sizing: border-box;
}
.block {
  font-size: 22px;
  padding: 20px 20px 0;
  overflow-wrap: break-word;
  @media (min-width: 1024px){
    padding-bottom: 20px;
  }
  &:last-child{
    padding-bottom: 20px;
  }
  & > * {
    @include block;
    line-height: 1.8;
    & :first-child {
      margin-top: 0;
    }
    a {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
  &.audio {
    figure {
      &.alignfull {
        max-width: none;
      }
      &.alignleft {
        & > * {
          max-width: 500px;
        }
      }
      &.alignright {
        text-align: right;
        & > * {
          max-width: 500px;
        }
      }
      audio {
        width: 100%;
      }
      figcaption {
        font-size: 0.71111em;
        text-align: center;
      }
    }
  }
  &.button {
    @include block;
    position: relative;
    text-align: left;
    cursor: pointer;
    .aligncenter {
      text-align: center;
      a {
        display: inline-block;
      }
    }
    .alignright {
      margin-left: 1em;
      float: right;
      margin-top: 0;
      a {
        margin-top: 0;
      }
    }
    .alignleft {
      float: left;
      margin-right: 1em;
      margin-top: 0;
      a {
        margin-top: 0;
      }
    }
    a {
      background: $color_1;
      color: $color_4;
      display: table;
      border-radius: 5px;
      padding: 0.875rem 1.5rem;
      text-decoration: none;
      margin: 0.75em 0 0;
      line-height: 1.2;
      font-weight: bolder;
    }
    .is-style-outline {
      a {
        background: $color_4;
        color: $color_1;
        border: 2px solid currentcolor;
      }
    }
    .is-style-squared {
      a {
        border-radius: 0;
      }
    }
    a.has-dgr-farbe-1-background-color {
      background-color: $color_1;
    }
    a.has-dgr-farbe-2-background-color {
      background-color: $color_2;
    }
    a.has-dgr-farbe-3-background-color {
      background-color: $color_3;
    }
    a.has-dgr-farbe-4-background-color {
      background-color: $color_4;
    }
    a.has-dgr-farbe-5-background-color {
      background-color: $color_5;
    }
    a.has-dgr-farbe-1-color {
      color: $color_1;
    }
    a.has-dgr-farbe-2-color {
      color: $color_2;
    }
    a.has-dgr-farbe-3-color {
      color: $color_3;
    }
    a.has-dgr-farbe-4-color {
      color: $color_4;
    }
    a.has-dgr-farbe-5-color {
      color: $color_5;
    }
  }
  &.code {
    pre {
      font-size: 14px;
      padding: .8em 1em;
      border: 1px solid currentcolor;
      code {
        font-size: 1.125em;
        white-space: pre-wrap;
        word-break: break-word;
      }
    }
  }
  &.columns {
    @include block;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @media(min-width: 1024px){
      flex-wrap: nowrap;
    }
    &.align-full {
      max-width: none;
    }
    .column {
      margin: 0;
      flex-basis: 100%;
      @media(min-width: 768px){
        flex-basis: 50%;
        margin: 0px 10px;
        &:first-of-type{
          margin-left: 0;
        }
        &:last-of-type{
          margin-right: 0;
        }
      }
    }
  }
  &.cover {
    min-height: 430px;
    position: relative;
    text-align: center;
    color: #ffffff;
    @include block;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    // &::before {
    //   content: '';
    //   position: absolute;
    //   left: 0;
    //   top:0;
    //   right: 0;
    //   bottom: 0;
    //   background-color: #222222;
    //   opacity: .5;
    //   z-index: 1;
    // }
    &.fullheight {
      min-height: calc( 100vh - 70px );
    }
    &.align-full {
      max-width: none;
    }
    .container {
      width: 100%;
      .overlay {
        position: absolute;
        left: 0;
        top:0;
        right: 0;
        bottom: 0;
        background-color: #222222;
        opacity: .5;
        z-index: 1;
        &.dgr-farbe-1 {
          background-color: $color_1;
        }
        &.dgr-farbe-2 {
          background-color: $color_2;
        }
        &.dgr-farbe-3 {
          background-color: $color_3;
        }
        &.dgr-farbe-4 {
          background-color: $color_4;
        }
        &.dgr-farbe-5 {
          background-color: $color_5;
        }
      }
      .Image, .Image span {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top:0;
        right: 0;
        bottom: 0;
        margin: 0;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .inner {
        position: relative;
        z-index: 1;
        @media (min-width: 1024px) {
          padding: 30px;
        }
      }
    }
  }
  &.custom {
    h1 {
      font-size: 2.8125em;
    }
    h2 {
      font-size: 2.25em;
    }
    h3 {
      font-size: 1.6875em;
    }
    h4 {
      font-size: 1.125em;
    }
    h5 {
      font-size: 0.88889em;
    }
    h6 {
      font-size: 0.71111em;
    }
  }
  &.file {
    &.align-full {
      max-width: none;
      div {
        max-width: none;
      }
    }
    &.align-center {
      text-align: center;
    }
    &.align-left {
      @include block;
      div {
        max-width: none;
        margin-right: 1em;
        margin-top: 0;
        float: left;
      }
    }
    &.align-right {
      @include block;
      div {
        max-width: none;
        margin-left: 1em;
        margin-top: 0;
        float: right;
      }
    }
    div {
      a[download] {
        background: $color_1;
        color: $color_4;
        display: table;
        border-radius: 5px;
        padding: 0.875rem 1.5rem;
        text-decoration: none;
        margin-top: 0.75em;
        font-weight: bolder;
      }
    }
  }
  &.heading {
    h1 {
      font-size: 2.25em;
    }
    h2 {
      font-size: 1.6875em;
    }
    h3 {
      font-size: 1.6875em;
    }
    h4 {
      font-size: 1.125em;
    }
    h5 {
      font-size: 0.88889em;
    }
    h6 {
      font-size: 0.71111em;
    }
    @media (min-width: 768px) {
      h1 {
        font-size: 2.8125em;
      }
      h2 {
        font-size: 2.25em;
      }
    }
  }
  &.html {
    @include block;
  }
  &.image {
    @include block;
    position: relative;
    .align-full {
      max-width: none;
    }
    .align-center {
      text-align: center;
    }
    .align-right {
      &  {
        margin-left: 1em;
        float: right;
      }
    }
    .align-left {
      float: left;
      margin-right: 1em;
    }
  }
  &.media-text {
    position: relative;
    background-color: inherit!important;
    margin: 0 auto;
    &.align-full {
      .inner {
        max-width: none;
      }
    }
    &.stacked {
      @media(max-width: 1023px) {
        padding-right: 0;
        padding-left: 0;
        .inner, .inner.position-right {
          display: block;
          direction: ltr;
          .text {
            transform: none;
            padding: 10px 10px 30px;
            min-width: unset;
          }
        }
      }
    }
    .inner {
      display: grid;
      grid-template-rows: auto;
      align-items: center;
      grid-template-columns: 70% auto;
      @media(min-width: 1400px){
        grid-template-columns: 62% auto ;
      }
      &.position-right {
        direction: rtl;
        & > * {
          direction: ltr;
          @supports (-ms-ime-align:auto) {
            direction: rtl;
          }
        }
        .text {
          transform: translateX(50%);
          padding: 50px;
        }
      }
    }
    .Image img{
      box-shadow: 0 6px 15px 2px rgba(0, 0, 0, 0.1);
      width: 100%;
      vertical-align:top
    }
    .text {
      box-shadow: 0 6px 15px 2px rgba(0, 0, 0, 0.1);
      transform: translateX(-50%);
      padding: 50px;
      min-width: 500px;
      background-color: $color_4;
      &.dgr-farbe-1 {
        background-color: $color_1;
      }
      &.dgr-farbe-2 {
        background-color: $color_2;
      }
      &.dgr-farbe-3 {
        background-color: $color_3;
      }
      &.dgr-farbe-4 {
        background-color: $color_4;
      }
      &.dgr-farbe-5 {
        background-color: $color_5;
      }
    }

  }
  &.nextPage {
    @include block;
    a {
      color: $color_1;
      margin: 0.5em;
      font-weight: bolder;
      &.active {
        pointer-events: none;
        color: currentcolor;
      }
    }
  }
  &.paragraph {
    .has-background {
      padding: 20px 30px;
    }
    .has-small-font-size {
      font-size: 19.5px;
    }
    .has-large-font-size {
      font-size: 26.5px;
      @media (min-width: 768px) {
        font-size: 36.5px;
      }
    }
    .has-huge-font-size {
      font-size: 36.5px;
      @media (min-width: 768px) {
        font-size: 49.5px;
      }
    }
    .has-drop-cap {
      &:first-letter {
        font-size: 3.375em;
        line-height: 1;
        font-weight: bold;
        margin: 0 0.25em 0 0;
        text-transform: uppercase;
        float: left;
      }
      &::after {
        content: '';
        display: table;
        clear: both;
        padding-top: 14px;
      }
    }
    .has-dgr-farbe-1-background-color {
      background-color: $color_1;
    }
    .has-dgr-farbe-2-background-color {
      background-color: $color_2;
    }
    .has-dgr-farbe-3-background-color {
      background-color: $color_3;
    }
    .has-dgr-farbe-4-background-color {
      background-color: $color_4;
    }
    .has-dgr-farbe-5-background-color {
      background-color: $color_5;
    }
    .has-dgr-farbe-1-color {
      color: $color_1;
    }
    .has-dgr-farbe-2-color {
      color: $color_2;
    }
    .has-dgr-farbe-3-color {
      color: $color_3;
    }
    .has-dgr-farbe-4-color {
      color: $color_4;
    }
    .has-dgr-farbe-5-color {
      color: $color_5;
    }
  }
  &.preformatted {
    pre {
      white-space: pre-wrap;
    }
  }
  &.quote {
    blockquote {
      border-left: 2px solid $color_1;
      padding-left: 1em;
      &[style*='text-align:center'] {
        border-left: 0;
        padding-left: 0;
      }
      &[style*='text-align:right'] {
        border-left: 0;
        padding-left: 0;
        border-right: 2px solid $color_1;
        padding-right: 1em;
      }
      p {
        margin: 1em 0;
      }
      cite {
        font-size: calc(1em / (1.25 * 1.125));
        font-style: normal;
        position: relative;
        opacity: 0.7;
      }
      &.is-style-large {
        border: none;
        padding: 0;
        font-style: italic;
        p {
          font-size: 1.6875em;
          margin: 22px 0;
        }
        cite {
          font-size: calc(1em / (1.25 * 1.125));
        }
      }
    }
  }
  &.separator {
    @include block;
    margin: 0 auto;
    position: relative;
    hr {
      border: 0;
      border-bottom: 2px solid currentcolor;
      margin: 0;
      text-align: center;
      max-width: 2.25em;
      position: relative;
      &.is-style-dots {
        border: 0;
        &::after {
          content: '\00b7 \00b7 \00b7';
          letter-spacing: 0.88889em;
          line-height: 1;
          font-size: 50px;
          color: currentcolor;
        }
      }
      &.is-style-wide {
        @include block;
      }
    }
  }
  &.spacer {
    padding: 0;
  }
  &.table {
    table {
      border-collapse: collapse;
      width: 100%;
      &.has-fixed-layout {
        table-layout: fixed;
      }
      &.alignwide {
        width: 100%;
        tbody {
          float: none;
        }
      }
      &.alignfull {
        max-width: none;
        width: 100%;
        tbody {
          float: none;
        }
      }
      &.aligncenter {
        tbody {
          float: none;
        }
      }
      &.alignright {
        width: 100%;
        tbody {
          float: right;
        }
      }
      &.has-subtle-pale-blue-background-color {
        tbody {
          tr {
            background-color: #e7f5fe;
          }
        }
      }
      &.has-subtle-pale-pink-background-color {
        tbody {
          tr {
            background-color: #fcf0ef;
          }
        }
      }
      &.has-subtle-pale-green-background-color {
        tbody {
          tr {
            background-color: #e9fbe5;
          }
        }
      }
      &.has-subtle-light-gray-background-color {
        tbody {
          tr {
            background-color: #f3f4f5;
          }
        }
      }
      &.is-style-stripes {
        &.has-subtle-pale-blue-background-color {
          tbody {
            tr {
              &:nth-child(2n+1) {
                background-color: #e7f5fe;
              }
              &:nth-child(2n) {
                background-color: $color_4;
              }
            }
          }
        }
        &.has-subtle-pale-pink-background-color {
          tbody {
            tr {
              &:nth-child(2n+1) {
                background-color: #fcf0ef;
              }
              &:nth-child(2n) {
                background-color: $color_4;
              }
            }
          }
        }
        &.has-subtle-pale-green-background-color {
          tbody {
            tr {
              &:nth-child(2n+1) {
                background-color: #e9fbe5;
              }
              &:nth-child(2n) {
                background-color: $color_4;
              }
            }
          }
        }
        &.has-subtle-light-gray-background-color {
          tbody {
            tr {
              &:nth-child(2n+1) {
                background-color: #f3f4f5;
              }
              &:nth-child(2n) {
                background-color: $color_4;
              }
            }
          }
        }
        tbody {
          tr {
            &:nth-child(2n+1) {
              background-color: #f3f4f5;
            }
            &:nth-child(2n) {
              background-color: $color_4;
            }
          }
        }
      }
      tbody {
        float: left;
        tr {
          td, th {
            border: 1px solid currentcolor;
            padding: .5em;
          }
        }
      }
    }
  }
  &.verse {
  }
  &.video {
    &.align-full {
      max-width: none;
      figure {
        max-width: none;
      }
    }
    &.align-center {
      text-align: center;
    }
    &.align-left {
      @include block;
      figure {
        max-width: none;
        margin-right: 1em;
        margin-top: 0;
        float: left;
        max-width: 50%;
      }
    }
    &.align-right {
      @include block;
      figure {
        max-width: none;
        margin-left: 1em;
        margin-top: 0;
        float: right;
        max-width: 50%;
      }
    }
    figure {
      video {
        width: 100%;
        &[poster]{
          object-fit: cover;
        }
      }
      figcaption {
        font-size: 0.71111em;
        text-align: center;
      }
    }
  }
  &.contact {
    form {
      display: flex;
      flex-wrap: wrap;
      color: $color_3;
      padding: 0 10px 20px;
      @media (min-width: 768px) {
        padding: 0 40px 60px;
      }
      .potty {
        display: none;
      }
      max-width: 1024px;
      margin: 0 auto;
      h2 {
        color: $color_3;
        padding: 10px;
        font-size: 30px;
        font-family: $headline;
        text-align: center;
        box-sizing: border-box;
        &.fullWidth {
          width: 100%;
        }
      }
      label {
        box-sizing: border-box;
        display: block;
        padding: 10px;
        font-size: 16px;
        &.fullWidth {
          width: 100%;
        }
        &.invalid {
          input, textarea {
            box-shadow: 0 0 1.5px 1px red;
            border-color: red;
          }
          input[type='checkbox'] + span::before{
            box-shadow: 0 0 1.5px 1px red;
            border-color: red;
          }
          input:valid {
            box-shadow: none;
            border-color: currentcolor;
          }
          input[type='checkbox']:checked + span::before {
            box-shadow: inset 0px 0px 0px 3px $color_4;
            border-color: currentcolor;
          }
        }
        span{
          line-height: 19px;
          margin-bottom: 10px;
          padding: 0 5px 0 0;
        }
        input{
          -moz-appearance: textfield;
          &[type='checkbox'] {
            -moz-appearance: checkbox;
            width: auto;
            display:none;
          }
          &[type='checkbox'] + span {
            display: block;
          }
          &[type='checkbox'] + span::before {
            width: 15px;
            height: 15px;
            border-radius: 4px;
            border: 2px solid $color_3;
            background-color: $color_4;
            display: block;
            content: '';
            float: left;
            margin-right: 5px;
          }
          &[type='checkbox']:checked + span::before {
            box-shadow: inset 0px 0px 0px 3px $color_4;
            background-color: $color_3;
          }
        }
        input,textarea {
        box-sizing: border-box;
          font-family: $content;
          width: 100%;
          font-size: 16px;
          background-color: transparent;
          color:  $color_3;
          border: 2px solid $color_3;
          border-radius: 4px;
          padding: 6px 8px;
        }
        textarea {
          width: 100%!important;
          border-radius: 4px;
        }
        @media(min-width: 768px) {
          &.halfWidth {
            width: 50%;
          }
        }
      }
      button {
        cursor: pointer;
        background-color: transparent;
        color: $color_3;
        border-color: $color_3;
        margin: 10px;
        border-style: solid;
        padding: 10px 14px;
        border-width: 2px;
        border-radius: 4px;
        font-size: .875rem;
        font-weight: bold;
        letter-spacing: .0892857143em;
        text-decoration: none;
        text-transform: uppercase;
        &:hover {
          background-color: rgba(44, 46, 56, 0.1);
        }
      }
    }
  }
}
