@import '../../variables';
.FourHundredFour {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 150px);
  h1 {
    font-size: 50px;
  }
}
