@import '../../variables.scss';
.Maps {
  min-height: 300px;
  height: 300px;
  @media (min-width:1024px) {
    min-height: calc( 100vw / 16 * 4);
    height: calc( 100vw / 16 * 4);
  }
  .notice {
    height: 100%;
    justify-content: center;
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 0 30px;
    text-align: center;
    p {
      margin: 0;
      a {
        display: inline-block;
        padding: 10px 0;
      }
    }
    button {
      cursor: pointer;
      background-color: transparent;
      color: $color_3;
      border-color: $color_3;
      margin: 10px 10px 0;
      border-style: solid;
      padding: 10px 14px;
      border-width: 2px;
      border-radius: 4px;
      font-size: .875rem;
      font-weight: bold;
      letter-spacing: .0892857143em;
      text-decoration: none;
      text-transform: uppercase;
      &:hover {
        background-color: rgba(44, 46, 56, 0.1);
      }
    }
  }
}
