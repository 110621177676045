@import '../variables';
.App {
  background-color: $color_4;
  color: $color_3;
  min-height: 100vh;
  font-family: $content;
}

a {
  color: inherit;
  text-decoration: none;
  &:hover {
    opacity: 0.8;
  }
  &.selected {
    padding-bottom: 3px;
    border-bottom: 2px solid;
  }
}

abbr, acronym {
  cursor: help;
}
